import React,{ useState, useEffect } from 'react';
import { StyleSheet, View, Text, Button, ActivityIndicator } from 'react-native';
import QRCode from "react-qr-code";
import { getTour, updateTour, analytics } from '../helpers/fbHelper'
import success from "../../assets/success.gif"
import Referral from "../components/utils/referral"


const UpdateVivid = (props) => {
  const vividTours = ['0699cJbL1gSLxoYLkJkp','3WQ4DJ7YW70QS7d3llPA','A9kyx5WGSP8CENhGYUBb','D2tiacpr66AUmNA1foxk','IswPt8MQI91z9FmOxxwx','NkgirkOWEHHiyVrC17JP','hqhvigof0vdI42gfmZdN','jmiUp0kh5HVEBh5nZ6kU','qwWVdSjR9kWx3F0iiyMe','rtFjJDzc5EF8Mje0V4gA','uQYxZniZaJ8AGQU1O8LK','wJFJsvNPBjBhz7dXSqgZ','x5TJckHmecxbpxU2Ksie']
  //const vividTours = ['VJAQ4m96wcaCulr1dIfq']
  const org = getTour('9ZoER6OpuY0qiNRdSZtD')

  const toursToUpdate = vividTours.map(val => {
    return updateTour(val)
  })

  if(org.isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large"/>
      </View>
    );
  }

  var i = 0 
  const stops = org.data.data().Stops
  for(var t of toursToUpdate) {
    t.mutate({
      Stops: stops
    })
  }

  return (
    <View>
      <Text>DONE!!!</Text>
    </View>
  )
}

const Test = ({ navigation, screenName }) =>{
  const url = new URLSearchParams(window.location.search);
  const id = url.get('tourId') ??  'CX8DU5InvKtEoSh3f16V'; //'VJAQ4m96wcaCulr1dIfq' 
  //const tour = getTour(id)
  const tour = { isLoading: false }
  const index = url.get('stopId') ?? 0
  var [showSuccess,setSuccess] = useState(false)

  if(tour.isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large"/>
      </View>
    );
  }

  /*
  const snapshot = tour.data;
  const { Name: name, Stops: stops, Guide: guide, Owner: owner, persona, guideProfilePic } = snapshot.data() ?? ''
  var stop = stops[index] ?? { media: [] }
  var media = stop.media.filter(val => val.type === 'referral')[0]
  var content = media?.content ?? []
  */

  return (
    <View style={styles.container}>
      <Text>This is a test screen...wait how did you get here??</Text>
    </View>
  )
}

export default Test

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center', 
    backgroundColor: '#fcfefc'
  },
  title: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'quicksand_bold',
    fontSize: 17
  },
  sheetContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    height: 300
  },
  video: {
    width: '100%',
    paddingBottom: 10
  }
});
